import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import {IsAuthorized} from '../logic/auth'


// https://joshtronic.com/2020/03/23/protected-routes-with-react-router-v5/
const RouteAuthenticated = ({ component: Component, path }: RouteProps) => {
  if (!IsAuthorized()) {
    return <Redirect to="/admin/login" />;
  }

  return <Route component={Component} path={path} />;
};

const RouteUnauthenticated = ({ component: Component, path }: RouteProps) => {
    if (IsAuthorized()) {
      return <Redirect to="/admin" />;
    }
  
    return <Route component={Component} path={path} />;
  };

export {RouteAuthenticated, RouteUnauthenticated};