import {User, Wish} from '../logic/types'

import TopBar from './TopBar'
import WishList from './WishList';
import Editor from './Editor';
import { useState } from 'react';

type ActiveWish = Wish | null;

const Admin = () => {
  const user: User = {
    "id" : "1",
    "name" : "Tobias"
  }

  const [activeWish, setActiveWish] = useState<ActiveWish>(null);
  const [lastRefresh, setLastRefresh] = useState<number>(Date.now())

  const onWishClick = (wish: Wish) => {
    setActiveWish(wish)
    window.scrollTo(0, 0);
  }

  const refreshRequired = () => {
    setLastRefresh(Date.now())
  }

  return (
    <div className="min-h-screen bg-gray-100">
        <TopBar user={user} />
        
        <div className="flex flex-col-reverse md:flex-row p-6 pb-0 gap-6 justify-center items-start">
            <WishList onWishClick={onWishClick} lastRefresh={lastRefresh}/>
            <Editor wish={activeWish} onRefreshRequired={refreshRequired}/>
        </div>

    </div>
);
}

export default Admin