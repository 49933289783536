import { Draggable } from 'react-beautiful-dnd';
import {Wish} from '../logic/types'

type WishItemProps = {
  index: number,
  wish: Wish,
  onClick: (wish: Wish) => void
}

const WishItem = (props: WishItemProps) => {

  const wish: Wish = props.wish;
  const onClick = () => {
    props.onClick(wish);
  }

  return (
    <Draggable draggableId={wish.id ?? "0"} index={props.index}>
      {provided => (
        <div onClick={onClick} className="cursor-pointer h-auto bg-white rounded overflow-hidden transition-shadow shadow-md hover:shadow-lg mb-6 " 
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}> 
          <div className="h-52 bg-blue-200 overflow-hidden relative">
            <img className="block h-full w-full z-0 absolute object-cover opacity-30"src={wish.imageUrl ?? undefined} alt="Wish Product" />
            <img className="block h-full w-full z-10 relative object-contain p-2 backdrop-filter backdrop-blur-sm"src={wish.imageUrl ?? undefined} alt="Wish Product" />
          </div>
          <div className="p-4">
            <h1 className="font-bold text-lg">{wish.title}</h1>
            <h2 className="italic text-md">{wish.category}</h2>
            <p className="">{wish.description}</p>
          </div>
        </div>
      )}
    </Draggable>
  );
} 

export default WishItem