import {DoPost} from './api';
import {User} from './types';

const KEY_AUTH_DATA = "data";

type AuthData = {
    token: string,
    user : User
}

export const GetAuthData = ()  => {
    const authString = localStorage.getItem(KEY_AUTH_DATA);
    console.log("Auth data retrieved");
    if (authString !== undefined && authString !== null) {
        const authData = JSON.parse(authString);
        if (authData !== undefined && authData !== null) {
            return authData as AuthData; 
        }
    }
    return null;
}

const SetAuthData = (authData: AuthData) => {
    if (authData !== undefined && authData.token.length > 0) {
        localStorage.setItem(KEY_AUTH_DATA, JSON.stringify(authData));
    }
    console.log("Auth data set")
}

export const IsAuthorized = () => {
    const authData = GetAuthData();
    console.log(authData);
    if (authData !== undefined && authData !== null) {
        return authData.token !== undefined && authData.token !== null && authData.token.length > 0;
    }
    return false;
}

export const Login = async (username: string, password: string) => {
    const data = await DoPost("/api/auth/login", {
        username: username,
        password: password
    });
    console.log(data);
    if (data.success !== undefined && data.success == true) {
        SetAuthData({
            token : data.token,
            user : {
                id : data.user.id,    
                name : data.user.name
            }
        });
    } 
    return data;
};

export const Logout = () => {
    localStorage.removeItem(KEY_AUTH_DATA);
    console.log("Auth data removed");
}
