import {useEffect, useState } from 'react';

import WishList from './WishList'
import TopBar from './TopBar'
import {User} from '../logic/types'

import * as ViewApi from '../logic/view'

const KEY_LAST_SELECTED_ID = "SELECTED_ID";

const View = () => {

    const [users, setUsers] = useState<User[]>([])
    const [selectedUser, setSelectedUserRaw] = useState<User | null>(null);

    const setSelectedUser = (user: User) => {
        setSelectedUserRaw(user);
        localStorage.setItem(KEY_LAST_SELECTED_ID, user.id);
    }

    // Load users and choose what the browser was looking at last
    useEffect(() => {
        const getUsers = async () => {
            const fetchedUsers = await ViewApi.GetUsers() as User[];
            if (fetchedUsers === undefined && fetchedUsers === null) {
                return;
            }
            setUsers(fetchedUsers)

            if (selectedUser === null && fetchedUsers.length > 0) {
                const lastSelectedId = localStorage.getItem(KEY_LAST_SELECTED_ID);
                console.log("lastSelectedId", lastSelectedId)
                if (lastSelectedId !== undefined && lastSelectedId !== null) {
                    const lastUser = fetchedUsers.find(user => user.id === lastSelectedId);
                    if (lastUser !== undefined) {
                        setSelectedUser(lastUser)
                    }else {
                        setSelectedUser(fetchedUsers[0])
                    }
                } else {
                    setSelectedUser(fetchedUsers[0])
                }
            }
        }
        getUsers();
    }, [])

    return (
        <div className="min-h-screen bg-gray-100">
            <TopBar users={users} selected={selectedUser} onUserChange={setSelectedUser} />
            <WishList user={selectedUser} />
        </div>
    );

}


export default View