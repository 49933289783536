import {useEffect, useState } from 'react';

import { DragDropContext, DropResult, Droppable} from 'react-beautiful-dnd';

import {Wish} from '../logic/types'
import WishItem from './WishItem'
import * as AdminApi from '../logic/admin'

type WishListProps = {
    lastRefresh : number
    onWishClick : (wish: Wish) => void
}

const WishList = (props: WishListProps) => {

    const [wishes, setWishes] = useState<Wish[]>([])
    useEffect(() => {
        const getWishlist = async () => {
            const wishlist = await AdminApi.GetWishlist();
            setWishes(wishlist)
        }
        getWishlist();
    }, [props.lastRefresh])
    
    const items = wishes?.map((wish,index) => {
        const item = <WishItem wish={wish} index={index} onClick={props.onWishClick}/>;
        return (<li className="" key={wish.id}>{item}</li>)
    }) 

    const reorder = async (startIndex: number, endIndex: number) => {
        const backup = Array.from(wishes);
        const wish = wishes[startIndex];

        const result = Array.from(wishes);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        
        setWishes(result);

        const success = await AdminApi.Reorder(wish, endIndex)

        if (!success) {
            setWishes(backup);
        }
    }

    const onDragEnd =  (result: DropResult) => {
        const {destination, source, draggableId} = result;
        const itemId = draggableId;
        if (!destination) {
          return;
        }
    
        if (destination.index === source.index) {
          return;
        }

        reorder(source.index, destination.index)
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="wishlist">
            {provided => (
                <ul className="container max-w-screen-lg mb-6 grid grid-cols-1" ref={provided.innerRef} {...provided.droppableProps}>
                    {items}
                    {provided.placeholder}    
                </ul>
            )}   
            </Droppable>
        </DragDropContext>
    )

}

export default WishList