import React, { useEffect, useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import ReactS3Uploader from "react-s3-uploader";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import * as AdminApi from '../logic/admin'
import { Wish } from '../logic/types';

type ImageFieldProps = {
    className: string
    currentWish: Wish
    onNewURL: (newURL: string) => void
}

const getSignedUrl = async (file: any, callback: any) => {
    const { url, signedUrl } = await AdminApi.GetSignedUrl(file.name, file.type);
    console.log("Signed URL:", signedUrl);
    callback({ signedUrl: signedUrl });
}

const ImageField = (props: ImageFieldProps) => {


    const [upProgress, setUpProgress] = useState<number | null>(null);

    let progressIndicator = upProgress != null ? <p>Uploader: {upProgress}%</p> : null;

    const onFinish = (result: any) => {
        const destination = result.signedUrl.split("?")[0];
        console.log("Destination URL:", destination);
        props.onNewURL(destination);
        
    }

    const onURLChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.onNewURL(event.target.value);
    }

    let uploadInput;

    useEffect(() => {
       if (uploadInput != null) {
        setUpProgress(null);
        uploadInput.value = null;
       }
    }, [props.currentWish])

    // if (props.currentWish.id)

    return (<div className={props.className}>

        <div className='flex flex-row place-content-between mt-4'>
            <ReactS3Uploader
                getSignedUrl={getSignedUrl}
                accept="image/*"
                onProgress={setUpProgress}
                onError={(e) => console.log("onError", e)}
                onFinish={onFinish}
                uploadRequestHeaders={{}}
                autoUpload={true}
                inputRef={cmp => uploadInput = cmp}
            />
            {progressIndicator}
        </div>

        <input
            name="imageUrl"
            value={props.currentWish.imageUrl ?? ""}
            onChange={onURLChange}
            type="text"
            className="mt-0 mb-6 block w-full px-0 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-blue-400"
            placeholder="Skriv URL'en på billedet her"
        />


    </div>);
    // return (<div className={props.className}>   
    //     <Tabs>
    //         <TabList>
    //             <Tab>Upload</Tab>
    //             <Tab>URL</Tab>
    //         </TabList>
    //         <TabPanel> 
    //             <div className='flex flex-row place-content-between mt-4'>
    //                 <ReactS3Uploader
    //                     getSignedUrl={getSignedUrl}
    //                     accept="image/*"
    //                     onProgress={setUpProgress}
    //                     onError={(e) => console.log("onError", e)}
    //                     onFinish={onFinish}
    //                     uploadRequestHeaders={{}}
    //                     autoUpload={true}
    //                     inputRef={cmp => uploadInput = cmp}
    //                 />
    //                 {progressIndicator}
    //             </div>
    //         </TabPanel>
    //         <TabPanel>
    //         <input
    //             name="imageUrl"
    //             value={props.currentURL ?? ""}
    //             onChange={onURLChange}
    //             type="text"
    //             className="mt-0 mb-6 block w-full px-0 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-blue-400"
    //             placeholder="Skriv URL'en på billedet her"
    //         />
    //         </TabPanel>
    //     </Tabs>

    // </div>);
}

export default ImageField