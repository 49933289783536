import { useState } from 'react';
import {User} from '../logic/types';
import {Logout, GetAuthData, IsAuthorized} from '../logic/auth';
import { useHistory, Redirect } from 'react-router-dom'

type TopBarProps = {
    user: User
}

const TopBar = (props: TopBarProps) => {

    const [redirect, setRedirect] = useState(false);

    const logoutPressed = () => {   
        Logout();
        setRedirect(true);
    }

    if (redirect) {
        return <Redirect to="/admin/login" />
    }

    const name = GetAuthData()?.user.name;
    return (
        <nav className="w-full h-20 bg-blue-400 flex py-6 px-4 justify-between items-center">
            <p className="text-lg text-white">Du er logget ind som <span className="font-bold">{name}</span></p>
            <button 
                className="h-12 rounded-full border-2 border-white px-4 py-2 text-white font-bold hover:bg-white hover:text-blue-400 transition-colors"
                onClick={logoutPressed}>
                LOG UD
            </button>
    
        </nav>
    )
}

export default TopBar