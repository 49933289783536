import { useState } from 'react';

import {Wish} from '../logic/types'
import classNames from 'classnames';

import chevron from '../res/chevron.svg';

type WishItemProps = {
  wish: Wish
}

const WishItem = (props: WishItemProps) => {

  const [open, setOpen] = useState(false);
  const wish: Wish = props.wish;
  let description = wish.description;
  let truncatable = false;
  const maxLetters = 30;
  if (description !== undefined && description !== null && description.length > maxLetters) {
    truncatable = true;
  }

  const onTap = () => setOpen(!open);

  const imageElement = 
    <div className="h-52 bg-blue-200 overflow-hidden relative">
      <img className="block h-full w-full z-0 absolute object-cover opacity-30" src={wish.imageUrl ?? undefined} alt="Wish Product" />
      <img className="block h-full w-full z-10 relative object-contain p-2 backdrop-filter backdrop-blur-sm" src={wish.imageUrl ?? undefined} alt="Wish Product" />
    </div>;
  const linkItem = wish.websiteUrl != null ? <a href={wish.websiteUrl} target="_blank" className="rounded-full border border-blue-400 px-4 py-1 text-blue-400 opacity-75 hover:opacity-100 transition-opacity">Gå til link</a> : null;
  return (
    <div onClick={onTap} className="group h-auto bg-white rounded overflow-hidden transition-shadow shadow-md hover:shadow-lg " > 
      {wish.imageUrl != null ? imageElement : null}
      <div className={classNames("p-4", {"cursor-pointer": truncatable})}>
        <div className="flex justify-between items-center h-10">
          <h1 className="font-bold text-lg">{wish.title}</h1>
          {linkItem}
        </div>
        <h2 className="italic text-md">{wish.category}</h2>
        <p className={classNames({
          "truncate" : truncatable && !open
        })}>{description}</p>
        <div className={classNames({"pt-3": truncatable})}>
          <img className={classNames("w-3","h-3","mx-auto", "transition-opacity", "transform", "transition-transform", {
            "opacity-50": truncatable,
            "group-hover:opacity-100": truncatable,
            "opacity-0": !truncatable,
            "rotate-180": open
          })}
          alt="Arrow indicating expand"
          src={chevron}/>
        </div>
      </div>
    </div>
  );
} 

export default WishItem