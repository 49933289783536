import {useEffect, useState } from 'react';

import {Wish, User} from '../logic/types'
import WishItem from './WishItem'

import * as ViewApi from '../logic/view'

type WishListProps = {
    user: User | null
}

const WishList = (props: WishListProps) => {

    const [wishes, setWishes] = useState<[Wish]>()
    useEffect(() => {
        console.log("Calling wishlist, ")
        const getWishlist = async (id: string) => {
            const wishlist = await ViewApi.GetWishlist(id);
            console.log("Wsihes, ", wishlist)
            setWishes(wishlist)
        }
        if (props.user !== null) {
            console.log(props.user);
            getWishlist(props.user.id);
        }
    }, [props.user])

    const items = wishes?.map((wish) => {
        const item = <WishItem wish={wish}/>;
        return (<li className="" key={wish.id}>{item}</li>)
    }) 

    return (
        <ul className="container mx-auto my-6 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 px-6" >
            {items}
        </ul>
    )

}

export default WishList