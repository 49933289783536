import {DoGet} from './api';
import {Wish} from './types';
import {GetAuthData} from './auth'

export const GetUsers = async () => {
    const data = await DoGet("/api/view/users", {});
    if (data.success !== undefined && data.success == true) {
       return data.users;
    } 
    if (data.success !== undefined && data.success == false) {
        return [];
    } 
    return null;
};

export const GetWishlist = async (userId: string) => {
    const data = await DoGet("/api/view/wishlist", {userId: userId});
    if (data.success !== undefined && data.success == true) {
       return data.wishlist;
    } 
    return null;
};