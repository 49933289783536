import { useState, useEffect } from 'react'
import {User} from '../logic/types'

type TopBarProps = {
    users: User[],
    selected: User | null,
    onUserChange: (user: User) => void
}

const TopBar = (props: TopBarProps) => {
    
    const userChanged = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const id = event.target.value;
        const user = props.users.find(user => user.id === id)
        if (user !== undefined) {
            props.onUserChange(user)
        }
    }
    
    
    const options = props.users.map(user => <option key={user.id} value={user.id} className="text-black">{user.name}</option>)
    const selectedId = props.selected !== null ? props.selected.id : "";

    return (
        <nav className="w-full h-20 bg-blue-400 flex justify-between items-center px-4">
            <div className="flex">
                <p className="text-lg align-middle text-white mr-2">Du ser ønskeliste for</p>
                <select 
                    value = {selectedId}
                    onChange={userChanged}
                    className="block text-left pl-2 pr-10 py-0 border-0 border-b-2 border-blue-200 focus:ring-0 focus:border-white bg-transparent font-bold text-white">
                    {options}
                </select>
            </div>
            <a href="/admin">
                <p className="h-12 rounded-full border-2 border-white px-4 py-2 text-white font-bold hover:bg-white hover:text-blue-400 transition-colors">Admin</p>
            </a>
        </nav>
    )
}

export default TopBar