import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import './tailwind.output.css';

import Admin from './admin/Admin'
import Login from './admin/Login'
import View from './view/View'

import {RouteAuthenticated, RouteUnauthenticated} from './admin/Routes'

import { IsAuthorized } from './logic/auth'

const App = () => {
  const authorized = IsAuthorized();
  console.log("APP: " + authorized);
  return (
    <Router>
      <Switch>
        <RouteUnauthenticated path="/admin/login" component={Login} />
        <RouteAuthenticated path="/admin" component={Admin} />
        <Route path="/">
          <View />
        </Route>
      </Switch>
    </Router>
    );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
