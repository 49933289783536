export const DoGet = async (url: string, queryData: any) => {
    url += "?" + Object.keys(queryData).map(key => 
        encodeURIComponent(key) + '=' + encodeURIComponent(queryData[key])
    ).join('&');

    const response = await fetch(url, {
        method : "GET",
        mode : "cors",
        headers : {
            "Content-Type" : "application/json",
        },
    });

    if (response == null) {
        console.error("Response is null");
        return { success : false };
    }
    if (response.status === 200) {
        return await response.json();
    }
    if (response.status === 404) {
        return {success: false, message: "Endpoint not found"};
    }
    console.error(await response.json());
    return { success : false };
} 

export const DoAuthenticatedGet = async (url:string, token: string, queryData: any) => {
    url += "?" + Object.keys(queryData).map(key => 
        encodeURIComponent(key) + '=' + encodeURIComponent(queryData[key])
    ).join('&');
    
    const response = await fetch(url, {
        method : "GET",
        mode : "cors",
        headers : {
            "Content-Type" : "application/json",
            "Authorization" : "Bearer " + token
        }
    });
    if (response == null) {
        console.error("Response is null");
        return { success : false };
    }
    if (response.status === 200) {
        return await response.json();
    }
    if (response.status === 404) {
        return {success: false, message: "Endpoint not found"};
    }
    console.error(await response.json());
    return { success : false }
} 


export const DoPost = async (url:string, bodyData: any) => {
    const response = await fetch(url, {
        method : "POST",
        headers : {
            "Content-Type" : "application/json"  
        },
        body : JSON.stringify(bodyData)
    });
    if (response == null) {
        console.error("Response is null");
        return { success : false };
    }
    if (response.status === 200) {
        return await response.json();
    }
    if (response.status === 404) {
        return {success: false, message: "Endpoint not found"};
    }
    if (response.status === 400) {
        return await response.json();
    }
    console.error(await response.json());
    return { success : false }
} 

export const DoAuthenticatedPost = async (url:string, bodyData: any, token: string) => {
    const response = await fetch(url, {
        method : "POST",
        mode : "cors",
        headers : {
            "Content-Type" : "application/json",
            "Authorization" : "Bearer " + token
        },
        body : JSON.stringify(bodyData)
    });
    if (response == null) {
        console.error("Response is null");
        return { success : false };
    }
    if (response.status === 200) {
        return await response.json();
    }
    if (response.status === 404) {
        return {success: false, message: "Endpoint not found"};
    }
    console.error(await response.text());
    return { success : false }
} 

