import React, {useEffect, useState } from 'react';

import {Wish} from '../logic/types'
import * as AdminApi from '../logic/admin'
import ImageField from './ImageField';

type EditorProps = {
  wish?: Wish | null
  onRefreshRequired : () => void
}

const defaultWish: Wish = {
  id: null,
  title: "",
  category: "",
  description : null,
  imageUrl : null,
  websiteUrl : null
}

const Editor = (props: EditorProps) => {

  const [wish, updateWish] = useState<Wish>(defaultWish);
  const [isEditing, setIsEditting] = useState<boolean>(false);

  useEffect(() => {
    if (props.wish !== null && props.wish !== undefined ) {
        updateWish(props.wish);
        setIsEditting(true);
    }
  }, [props.wish]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    updateWish(prevState => {
      return {
        ...prevState, 
        [name] : value
      }
    })
  }

  const handleImageChange = (newURL: string) => {
    updateWish(prevState => {
      return {
        ...prevState, 
        imageUrl : newURL
      }
    })
  }

  const clear = () => {
    updateWish(defaultWish);
    setIsEditting(false);
  }
  
  const save = async () => {
    console.log("Trying to save", wish);

    if (wish.websiteUrl !== null && wish.websiteUrl !== undefined && !(wish.websiteUrl.startsWith("http://") || wish.websiteUrl.startsWith("https://"))) {
      wish.websiteUrl = "http://" + wish.websiteUrl;
    }

    if(isEditing) {
      const result = await AdminApi.Update(wish);
      if (result !== null) {
        props.onRefreshRequired();
        clear();
      }
    } else {
      const result = await AdminApi.Add(wish);
      if (result !== null) {
        props.onRefreshRequired();
        clear();
      }
    }
  }

  const deletePressed = async () => {
    if(isEditing) {
      console.log("Trying to delete", wish);
      const result = await AdminApi.Delete(wish);
      if (result === true) {
        props.onRefreshRequired();
        clear();
      } else {
        console.error("Could not delete ", wish);
      }
    } else {
      console.error("Delete pressed when not editing");
    }
  }

  const clearEnabled = isEditing || 
                     (!isEditing && ( wish.title.length > 0 || 
                                      wish.category.length > 0 || 
                                     (wish.description !== null && wish.description !== "") ||
                                     (wish.imageUrl !== null && wish.imageUrl !== "") ||
                                     (wish.websiteUrl !== null && wish.websiteUrl !== "")));

  const saveEnabled = isEditing || 
                    (!isEditing && ( wish.title.length > 0 && wish.category.length > 0 ));

  const deleteEnabled = isEditing;

  return (
    <div className="flex flex-col w-full max-w-screen-sm md:w-1/2 h-auto bg-white shadow-md rounded-sm p-6">
      <div className="flex flex-row justify-between items-center">
        <p className="font-semibold text-sm">{isEditing ? "Redigerer ønske" : "Nyt ønske"}</p>
        <button 
          className="block w-24 h-12 rounded-full border-2 border-blue-400 px-4 py-2 text-blue-400 font-bold hover:bg-blue-400 hover:text-white transition disabled:opacity-50 disabled:text-blue-400 disabled:bg-white disabled:cursor-default"
          onClick={clear}
          disabled={!clearEnabled}
          >
            RYD
        </button>
      </div>

      <label className="block">
          <span className="text-gray-700 text-xl font-semibold">Titel</span>
          <input
              name="title"
              onChange={handleChange}
              value={wish.title}
              type="text"
              className="mt-0 mb-6 block w-full px-0 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-blue-400"
              placeholder="Skriv titlen på ønsket her"
          />
      </label>

      <label className="block">
          <span className="text-gray-700 text-xl font-semibold">Kategori</span>
          <input
              name="category"
              onChange={handleChange}
              value={wish.category}
              type="text"
              className="mt-0 mb-6 block w-full px-0 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-blue-400"
              placeholder="Skriv kategorien på ønsket her"
              />
      </label>

      <label className="block">
          <span className="text-gray-700 text-xl font-semibold">Beskrivelse</span>
          <textarea
              name="description"
              onChange={handleChange}
              value={wish.description ?? ""}
              className="mt-2 mb-6 block w-full px-2 border-2 border-gray-200 focus:ring-0 focus:border-blue-400"
              placeholder="Skriv beskrivelsen på ønsket her"
          />
      </label>

      <label className="block">
          <span className="text-gray-700 text-xl font-semibold">Billede URL</span>
          <ImageField className="mt-2 mb-6" onNewURL={handleImageChange} currentWish={wish} />   
      </label>

         

      <label className="block">
          <span className="text-gray-700 text-xl font-semibold">Hjemmeside URL</span>
          <input
              name="websiteUrl"
              onChange={handleChange}
              value={wish.websiteUrl ?? ""}
              type="text"
              className="mt-0 mb-6 block w-full px-0 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-blue-400"
              placeholder="Skriv URL/linket til forhandlerens hjemmeside her"
          />
      </label>

      <div className="flex justify-between">
        <button 
          className="block w-24 h-12 flex-initial rounded-full border-2 border-blue-400 px-4 py-2 text-blue-400 font-bold hover:bg-blue-400 hover:text-white transition disabled:opacity-50 disabled:text-blue-400 disabled:bg-white disabled:cursor-default"
          onClick={deletePressed}
          disabled={!deleteEnabled}>
          SLET
        </button>

        <button 
          className="block w-24 h-12 flex-initial rounded-full border-2 border-blue-400 px-4 py-2 text-blue-400 font-bold hover:bg-blue-400 hover:text-white transition disabled:opacity-50 disabled:text-blue-400 disabled:bg-white disabled:cursor-default"
          onClick={save}
          disabled={!saveEnabled}>
          GEM
        </button>

      </div>

    </div>
  );
} 

export default Editor