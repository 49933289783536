import {DoAuthenticatedPost, DoAuthenticatedGet} from './api';
import {Wish} from './types';
import {GetAuthData} from './auth'

export const GetWishlist = async () => {
    const token = GetAuthData()?.token;
    if (token === undefined) {
        return null;
    }
    const data = await DoAuthenticatedGet("/api/admin/wishlist", token, {});
    if (data.success !== undefined && data.success == true) {
       return data.wishlist;
    } 
    return null;
};

export const Add = async (wish: Wish) => {
    const token = GetAuthData()?.token;
    if (token === undefined) {
        return null;
    }
    const body = {"wish" : wish}
    const data = await DoAuthenticatedPost("/api/admin/add", body, token);
    if (data.success !== undefined && data.success == true) {
       return data.wish;
    } 
    return null;
};

export const Update = async (wish: Wish) => {
    const token = GetAuthData()?.token;
    if (token === undefined) {
        return null;
    }
    const body = {"wish" : wish}
    const data = await DoAuthenticatedPost("/api/admin/update", body, token);
    if (data.success !== undefined && data.success == true) {
       return data.wish;
    } 
    return null;
};


export const Delete = async (wish: Wish) => {
    const token = GetAuthData()?.token;
    if (token === undefined) {
        return false;
    }
    const body = {"wish" : wish}
    const data = await DoAuthenticatedPost("/api/admin/delete", body, token);
    if (data.success !== undefined && data.success == true) {
       return true;
    } 
    return false;
};


export const Reorder = async (wish: Wish, newIndex: number) => {
    const token = GetAuthData()?.token;
    if (token === undefined) {
        return null;
    }
    const body = {
        "wish" : wish,
        "newIndex" : newIndex
    }
    const data = await DoAuthenticatedPost("/api/admin/reorder", body, token);
    if (data.success !== undefined && data.success == true) {
       return true;
    } 
    return false;
};

export const GetSignedUrl = async (objectName: string, contentType: string) => {
    const token = GetAuthData()?.token;
    if (token === undefined) {
        return null;
    }

    const result = await DoAuthenticatedGet("/api/admin/signedS3Url", token, {objectName, contentType});
    if (result.success !== undefined && result.success == true) {
       return result.data;
    } 
    return null;
};