import React, {useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import {Login as AuthLogin, IsAuthorized} from '../logic/auth'

const Login = () => {
    
    const [data, setData] = useState({
        username: "",
        password: ""
    })

    const [redirect, setRedirect] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setData(prevState => {
            return {
            ...prevState, 
            [name] : value
            }
        })
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && data.username.length > 0 && data.password.length > 0) {
            loginPressed()
        }
    }
    
    const loginPressed = async () => {
        const loginResult = await AuthLogin(data.username, data.password);
        if (loginResult != undefined && loginResult != null && loginResult.success === true) {
            setErrorMessage(null);
            setRedirect(true);
        } else if (loginResult != undefined && loginResult != null && loginResult.success === false) {
            setErrorMessage(loginResult.message);
        } else {
            setErrorMessage("Something's up...");
            console.log("Not redirecting");
        }
    }
    
    if (redirect) {
        return <Redirect to="/admin" />
    }

    const buttonEnabled = data.username.length > 0 && data.password.length > 0;

    const errorMessageElement = errorMessage ? <p className="text-red-700">{errorMessage}</p> : null;

    return (
    <div className="h-screen bg-gray-100">
        <nav className="w-full h-20 bg-blue-400 flex py-6 px-4 justify-between items-center">
            <p className="text-lg text-white">Ønskeliste Administrator Panel - Log ind på denne side</p>
            <a href="/">
                <p className="h-12 rounded-full border-2 border-white px-4 py-2 text-white font-bold hover:bg-white hover:text-blue-400 transition-colors">ØNSKELISTER</p>
            </a>

        </nav>
        
        <h1 className="text-center my-24 text-4xl font-bold text-blue-400">Velkommen til TobiJes' Ønskeliste Service</h1>

        <div className="px-6">

            <div className="flex flex-col w-full md:w-1/2 xl:w-1/3 mx-auto mt-6 bg-white shadow-md rounded-md p-6">

                <label className="block">
                    <span className="text-gray-700 text-xl font-semibold">Brugernavn</span>
                    <input
                        name="username"
                        onChange={handleChange}
                        value={data.username}
                        type="text"
                        className="mt-0 mb-6 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-blue-400"
                        placeholder="Indtast dit brugernavn her..."
                    />
                </label>

                <label className="block">
                    <span className="text-gray-700 text-xl font-semibold">Password</span>
                    <input
                        name="password"
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        value={data.password}
                        type="password"
                        className="mt-0 mb-6 block w-full px-0.5 border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-blue-400"
                        placeholder="Indtast dit password her..."
                    />
                </label>
                <button 
                    className="block w-36 h-12 ml-auto rounded-full border-2 border-blue-400 px-4 py-2 text-blue-400 font-bold hover:bg-blue-400 hover:text-white transition disabled:opacity-50 disabled:text-blue-400 disabled:bg-white disabled:cursor-default" 
                    onClick={loginPressed}
                    disabled={!buttonEnabled}
                    >
                        LOG IND
                    </button>
                {errorMessageElement}
                
            </div>
        </div>

        
    </div>
);
}

export default Login